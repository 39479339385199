<template>
  <v-app>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
    <template v-if="!disableSite">
      <top-panel/>
      <div ref='routerParent' style="width: 100%; height: 100%">
        <router-view/>
      </div>
    </template>
    <template v-else>
      <disable-site/>
    </template>
  </v-app>
</template>

<script>
import {debounce} from 'vue-debounce';
import axios from 'axios';
import TopPanel from "./components/topPanel/topPanel";
import DisableSite from "./views/DisableSite";

export default {
  name: 'App',
  components: {TopPanel, DisableSite},
  data() {
    return {
      rescaleFontDebounced: debounce(newScale => {
        const routerParent = this.$refs.routerParent;

        for (const el of routerParent.querySelectorAll('*')) {
          this.rescaleFontForElement(el, newScale);
        }
      }, '200ms')
    }
  },
  methods: {
    flag() {
      axios.get('/api/getFlag/?task_id=' + localStorage.getItem('task_id'))
          .then((response) => {
            if (!response.data.flag) {
              localStorage.removeItem('task_id');
            }
            localStorage.setItem('flag', response.data.flag);
            this.$store.commit('setFlag', response.data.flag);
          }).catch((error) => {});
      return true
    },
    rescaleFontObserver() {
      const element = this.$refs.routerParent;

      var observer = new MutationObserver( mutations => {
        mutations.forEach(mutation => {
          if (!mutation.addedNodes.length) return

          const scale = this.$store.getters.fontScale;
          this.rescaleFont(scale)
          // this.rescaleFontForElement(mutation.target, scale)
        })
      });

      observer.observe(element, {childList: true, subtree: true});
    },
    rescaleFontForElement(element, newScale) {
      const isColumnHeader = element.getAttribute('role') === 'columnheader';
      if (!element.innerText || (element.children.length && !isColumnHeader)) return;
      if (element.classList.contains('v-icon')) return;

      const style = window.getComputedStyle(element);
      let baseFontSize = parseFloat(element.getAttribute('base-font-size'));

      if (!baseFontSize) {
        const fontSize = style.getPropertyValue('font-size');
        element.setAttribute('base-font-size', fontSize);
        baseFontSize = parseFloat(style.getPropertyValue('font-size'));
      }

      element.style.setProperty('font-size', `${baseFontSize * newScale}px`);
    },
    rescaleFont(newScale) {
      this.rescaleFontDebounced(newScale)
    }
  },
  computed: {
    disableSite() {
      return (
        this.$store
        && this.$store.getters.disableSite 
        && this.$route.name !== 'login'
      )
    }
  },
  watch: {
    '$store.getters.fontScale': function (newScale) {
      this.rescaleFont(newScale);
    }
  },
  beforeMount() {
    setInterval(this.flag, 10000);
  },
  mounted() {
    this.rescaleFont(this.$store.getters.fontScale);
    this.rescaleFontObserver();
  } 
}
</script>
<style lang="scss">
.v-toolbar__extension {
  padding: 0;
}

.w-100 {
  width: 100%;
}

.v-dialog__content--active {
  z-index: 550 !important;
}

.v-btn--router {
  .material-icons {
    display: inline-flex !important;
    position: relative !important;
  }
}
.table-title {
  font-size: 17px;
  margin-right: 10px;
}
</style>
