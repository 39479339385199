<template>
    <div style="margin: auto;">
        <h1>{{ pageTitle }}</h1>
        <template
            v-if="isOwnerOrAdmin"
        >
            <v-progress-linear 
                v-model="progress"
                :indeterminate="!progress"
            >
            </v-progress-linear>
            <div 
                class="progress-text"
                v-if="max"
            >
                {{ current }} / {{ max }}
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "DisableSite",
        data() {
            return {
                current: 0,
                max: 0,
                type: undefined,
            }
        },
        computed: {
            isOwnerOrAdmin() {
                return this.$store.getters.isOwner || this.$store.getters.isAdmin
            },
            progress() {
                return this.current / this.max * 100;
            },
            pageTitle() {
                let defaultTitle = 'Technical works. Please wait.';

                if (!this.isOwnerOrAdmin)
                    return defaultTitle;

                if (this.type === 'check') {
                    return 'Checking data integrity. Please wait.'
                } else if (this.type === 'save') {
                    return 'Importing data. Please wait.'
                }

                return defaultTitle;
            }
        },
        methods: {
            setupDBUploadProgressWebsocket() {
                let websocket = new WebSocket(
                    location.origin.replace(/^http/, "ws") + "/ws/db-import"
                );
                websocket.onmessage = (event) => {
                    let data = JSON.parse(event.data).data;
                    if (data.type === null) {
                        websocket.close();
                        return;
                    }
                    this.current = data.current_progress;
                    this.max = data.max_progress;
                    this.type = data.progress_type;

                }
            }
        },
        beforeMount() {
            if (this.isOwnerOrAdmin)
                this.setupDBUploadProgressWebsocket();
        }
    }
</script>

<style scoped>
.progress-text {
    color: #1b70f7;
    text-align: center;
    font-weight: 500;
}
</style>
