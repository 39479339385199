import './assets/global.css';

import Vue from 'vue'
import './plugins/vuetify'

import App from './App.vue'
import router from './router'
import store from './store/store'

import 'material-icons'

import VueApexCharts from 'vue-apexcharts'

import VueTheMask from 'vue-the-mask'

import companyConfig from '../public/companyConfig.js';

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(VueTheMask);
Vue.component('apexchart', VueApexCharts);

window.$languages = JSON.parse(document.getElementById('config').innerHTML);

import VueTranslate from 'vue-translate-plugin';
import axios from "axios";

Vue.use(VueTranslate);

Vue.locales(window.$languages.locale);

import moment from "moment";
import VueMomentJS from "vue-momentjs";

Vue.use(VueMomentJS, moment);


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.get('/api/getFlag/?task_id=' + localStorage.getItem('task_id'))
    .then((response) => {
        localStorage.setItem('flag', response.data.flag);
        store.commit('setFlag', response.data.flag);
    }).catch((error) => { });

router.beforeEach((to, from, next) => {
    document.title = to.meta.title + ' | ' + companyConfig.title;
    axios.interceptors.request.use((config) => {
        let token = localStorage.getItem('jwt');
        if (token) {
            config.headers.common['Authorization'] = 'Token ' + token;
        }
        return config
    }, (error => {
        return Promise.reject(error);
    }));
    axios.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            store.commit('removeToken');
            axios.defaults.headers.common.pop('Authorization');
            next('login');
        }
        return Promise.reject(error);
    });

    if (to.name === 'login' && store.getters.loggedIn) {
        next('dashboard');
    }

    next();
});

router.onReady(() => {
    store.dispatch('setRoles');
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
