import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import axios from 'axios';

import config from '../../public/companyConfig.js';

export default new Vuex.Store({
    state: {
        ...config,
        MenuVisible: false,
        isPREPAYMENT: JSON.parse(localStorage.getItem('isPrepayment')) || false,
        currency: '$',
        balance: localStorage.getItem('balance') || 0,
        jwt: localStorage.getItem('jwt') || null,
        is_owner: JSON.parse(localStorage.getItem('is_owner') || false),
        is_admin: JSON.parse(localStorage.getItem('is_admin')) || false,
        is_manager: JSON.parse(localStorage.getItem('is_manager')) || false,
        is_reseller: JSON.parse(localStorage.getItem('is_reseller')) || false,
        pk: JSON.parse(localStorage.getItem('pk')),
        disableSite: JSON.parse(localStorage.getItem('flag')),
        fontScale: Number(localStorage.getItem('font_scale')) || 1,
    },
    getters: {
        fontScale(state, getters) {
            return getters.loggedIn ? state.fontScale : 1;
        },
        disableSite(state) {
            return state.disableSite;
        },
        menuVisible(state) {
            return state.MenuVisible;
        },
        isPREPAYMENT(state) {
            return state.isPREPAYMENT;
        },
        currency(state) {
            return state.currency;
        },
        getBalance(state) {
            return state.balance;
        },
        jwt(state) {
            return state.jwt
        },
        loggedIn(state) {
            return state.jwt != null
        },
        isOwner(state) {
            return state.is_owner
        },
        isAdmin(state) {
            return state.is_admin
        },
        isManager(state) {
            return state.is_manager
        },
        isReseller(state) {
            return state.is_reseller
        },
        getPk(state) {
            return state.pk
        },
        balance(state) {
            return state.balance;
        }
    },
    mutations: {
        setFontScale(state, scale) {
            state.fontScale = scale;
        },
        set(state, { type, items }) {
            state[type] = items;
        },
        setToken(state, token) {
            state.jwt = token
        },
        removeToken(state) {
            state.jwt = null
        },
        setRoles(state) {
            state.is_owner = JSON.parse(localStorage.getItem('is_owner'));
            state.is_admin = JSON.parse(localStorage.getItem('is_admin'));
            state.is_manager = JSON.parse(localStorage.getItem('is_manager'));
            state.is_reseller = JSON.parse(localStorage.getItem('is_reseller'));
        },
        setPk(state) {
            state.pk = JSON.parse(localStorage.getItem('pk'));
        },
        setFlag(state) {
            state.disableSite = JSON.parse(localStorage.getItem('flag'));
        },
        setKindPayment(state) {
            state.isPREPAYMENT = JSON.parse(localStorage.getItem('isPrepayment'));
        },
        setBalance(state, new_balance) {
            state.balance = new_balance;
        }
    },
    actions: {
        increaseFontScale({ commit }) {
            let scale = this.getters.fontScale;
            let newScale = Math.min(scale * 1.2, 1.8);
            localStorage.setItem('font_scale', newScale);
            commit('setFontScale', newScale);
        },
        decreaseFontScale({ commit }) {
            let scale = this.getters.fontScale;
            let newScale = Math.max(1, scale / 1.2);
            localStorage.setItem('font_scale', newScale);
            commit('setFontScale', newScale);
        },
        resetFontScale({ commit }) {
            localStorage.setItem('font_scale', 1);
            commit('setFontScale', 1);
        },
        switchMenu({ commit }) {
            let inverseValue = !this.getters.menuVisible;
            commit('set', { type: 'MenuVisible', items: inverseValue });
        },
        setRoles({ commit }) {
            commit('setRoles');
        },
        setBalance({ commit }, balance) {
            localStorage.setItem('balance', balance);
            commit('set', { type: 'balance', items: balance })
        },
        setJWT({ commit }, token) {
            localStorage.setItem('jwt', token);
            commit('setToken', token)
        },
        removeJWT({ commit }) {
            localStorage.removeItem('jwt');
            commit('removeToken');
        },
        LOGIN(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/token/login/`, {
                    username: credentials.username,
                    password: credentials.password,
                    recaptcha: credentials.recaptcha,
                })
                    .then(response => {
                        if (response.status === 200) {
                            const token = response.data.auth_token;
                            context.dispatch('setJWT', token);
                            context.dispatch('setBalance', response.data.balance);
                            localStorage.setItem('is_owner', response.data.is_owner);
                            localStorage.setItem('is_admin', response.data.is_admin);
                            localStorage.setItem('is_manager', response.data.is_manager);
                            localStorage.setItem('is_reseller', response.data.is_reseller);
                            localStorage.setItem('pk', response.data.pk);
                            localStorage.setItem('isPrepayment', response.data.isPrepayment);
                            context.commit('setRoles');
                            context.commit('setPk');
                            context.commit('setKindPayment');
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        LOGOUT(context) {
            return new Promise((resolve, reject) => {
                axios.post('/api/token/logout/').then((response) => {
                    localStorage.clear();
                    context.commit('removeToken');
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }
})
