<template>
  <div v-if="show">
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top
    >
      {{ t(text) }}
      <v-btn
          flat
          @click="snackbar = false"
      >
        {{ t('Close') }}
      </v-btn>
    </v-snackbar>
    <top-panel-menu/>
    <v-toolbar color="primary" dark extended extension-height="4" app
               v-if="$store.getters.loggedIn"

    >
      <v-toolbar-side-icon
          @click.stop="$store.dispatch('switchMenu')"/>
      <v-toolbar-title class="headline text-uppercase d-flex align-center">
        <span class="font-weight-light d-flex align-center">
          <a href="https://umt-tv.com/" class="d-flex align-center">
            <img src="/company_logo.png" style="max-width: 150px; max-height: 40px;" alt="logo">
          </a>
          <span v-if="!isMobile" style="margin-left: 10px;">{{ title }}</span>
        </span>
      </v-toolbar-title>
      <v-toolbar-title class="headline text-uppercase" v-if="!$store.getters.isOwner">
        <span class="font-weight-light">{{ this.balance }}</span>
      </v-toolbar-title>
      <top-panel-settings v-if="$store.getters.isOwner"/>
      <v-btn text
             flat
             color="secondary"
             @click="logout"
             v-if="!isMobile"
      >
        <span>{{ t('Logout') }}</span>
      </v-btn>
      <div v-else @click="logout" style="margin-left: 5px;">
        <v-icon>logout</v-icon>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import TopPanelMenu from "./topPanelMenu";
import TopPanelSearch from "./topPanelSearch";
import TopPanelSettings from "./topPanelSettings";
import Home from "../../views/Home/Home";

import axios from "axios";

export default {
  name: "topPanel",
  components: {Home, TopPanelSettings, TopPanelSearch, TopPanelMenu},
  data() {
    return {
      title: this.$store.state.title,
      drawer: false,
      loadingPercent: 0,
      loadTime: 0,
      loading: true,
      snackbar: false,
      text: 'Oops... Something went wrong',
      timeout: 5000,
    }
  },
  watch: {
    loadingPercent(val) {
      if (val >= 100) {
        this.loading = false;
        clearInterval(this.interval);
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT').then((response) => {
        this.text = "Successful";
        this.snackbar = true;
        this.$router.push('/login');
      }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
	getBalance() {
	  if (!this.$store.state.jwt) return;

	  axios.get('/api/my-balance/')
		.then(response => {
			let balance = response.data.balance;
			this.$store.commit('setBalance', balance);
		})
		.catch(error => {
			this.text = 'Error while loading balance';
			this.snackbar = true;
		})
	  },
  },
  computed: {
    show: function () {
      return !["login", "login token", "register", "forgot"].includes(this.$route.name)
    },
    isMobile() {
      return window.innerWidth < 450;
    },
	balance () {
	  return parseFloat(this.$store.state.balance).toFixed(2) + this.$store.getters.currency;
	}
  },
  mounted() {
	  this.getBalance();
  }
}
</script>

<style lang="scss">
.v-toolbar {
  z-index: 500 !important;
}
</style>
