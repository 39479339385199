<template>
   <v-layout class="toppanel-search">
       <v-autocomplete
               v-model="select"
               :loading="loading"
               :items="items"
               :search-input.sync="search"
               cache-items
               class="mx-3"
               flat
               hide-no-data
               hide-details
               label="Search"
               solo-inverted
       >
       </v-autocomplete>
   </v-layout>
</template>

<script>
    export default {
        name: "topPanelSearch",
        data(){
          return{
              loading: false,
              items: [],
              search: null,
              select: null,
              states: [
                  'Alabama',
                  'Alaska',
                  'American Samoa',
                  'Arizona',
                  'Arkansas',
                  'California',
                  'Colorado',
                  'Connecticut',
                  'Delaware',
                  'District of Columbia',
                  'Federated States of Micronesia',
                  'Florida',
                  'Georgia',
                  'Guam',
                  'Hawaii',
                  'Idaho',
                  'Illinois',
                  'Indiana',
                  'Iowa',
                  'Kansas',
                  'Kentucky',
                  'Louisiana',
                  'Maine',
                  'Marshall Islands',
                  'Maryland',
                  'Massachusetts',
                  'Michigan',
                  'Minnesota',
                  'Mississippi',
                  'Missouri',
                  'Montana',
                  'Nebraska',
                  'Nevada',
                  'New Hampshire',
                  'New Jersey',
                  'New Mexico',
                  'New York',
                  'North Carolina',
                  'North Dakota',
                  'Northern Mariana Islands',
                  'Ohio',
                  'Oklahoma',
                  'Oregon',
                  'Palau',
                  'Pennsylvania',
                  'Puerto Rico',
                  'Rhode Island',
                  'South Carolina',
                  'South Dakota',
                  'Tennessee',
                  'Texas',
                  'Utah',
                  'Vermont',
                  'Virgin Island',
                  'Virginia',
                  'Washington',
                  'West Virginia',
                  'Wisconsin',
                  'Wyoming'
              ],
          }
        },
        watch: {
            search (val) {
                val && val !== this.select && this.querySelections(val)
            }
        },
        methods: {
            querySelections (v) {
                this.loading = true
                // Simulated ajax query
                setTimeout(() => {
                    this.items = this.states.filter(e => {
                        return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
                    });
                    this.loading = false
                }, 500)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>