<template>
  <div>
    <v-dialog v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition">
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          top
      >
        {{ t(text) }}
        <v-btn
            flat
            @click="snackbar = false"
        >
          {{ t('Close') }}
        </v-btn>
      </v-snackbar>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on">
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary" extended extension-height="4">
          <v-btn v-show="!this.progress" icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ t('Settings') }}</v-toolbar-title>
          <v-spacer>
          </v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="saveSettings">{{ t('Save') }}</v-btn>
            <!--<v-btn color="error" flat @click="dialog = false">Выйти</v-btn>-->
          </v-toolbar-items>
        </v-toolbar>
        <!--                <v-progress-linear v-show="this.progress" :indeterminate="true"-->
        <!--                                   class="px-0"-->
        <!--                                   color="indigo darken-2"-->
        <!--                                   height="4">-->
        <!--                </v-progress-linear>-->
        <v-divider>
        </v-divider>

        <v-form ref="form">
          <v-list three-line subheader>
            <v-subheader>{{ t('Payment') }}</v-subheader>
            <v-list-tile avatar>
              <v-list-tile-content>
                <v-select
                    :items="kind"
                    :label="t('Choose')"
                    solo
                    :rules="[rules.required]"
                    v-model="kind_payment"
                >
                  <template slot='selection' slot-scope='{ item }'>
                    {{ t(item.text) }}
                  </template>
                  <template slot='item' slot-scope='{ item }'>
                    {{ t(item.text) }}
                  </template>
                </v-select>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-content>
                <v-text-field
                    :label="t('Currency')"
                    type="text"
                    :rules="[rules.currency,rules.required]"
                    v-model="currency"
                >
                </v-text-field>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-divider>
          </v-divider>

          <v-list three-line subheader>
            <v-subheader>{{ t('Payment period') }}</v-subheader>
            <v-list-tile avatar>
              <v-list-tile-content>
                <v-select
                    :items="period"
                    :label="t('Choose')"
                    solo
                    :rules="[rules.required]"
                    v-model="kind_period"
                >
                  <template slot='selection' slot-scope='{ item }'>
                    {{ t(item.text) }}
                  </template>
                  <template slot='item' slot-scope='{ item }'>
                    {{ t(item.text) }}
                  </template>
                </v-select>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar>
              <v-list-tile-content>
                <v-text-field
                    :label="t('Quantity (of payment period)')"
                    v-model="numberOfPeriod"
                    type="number"
                    :rules="[rules.counter, rules.number]"
                >
                </v-text-field>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-divider> </v-divider>

          <v-subheader>{{ t('Server preferences:') }}</v-subheader>
          <v-layout
              wrap
              px-3
          >
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Cards prefix')"
                  type="number"
                  :rules="[rules.rrrCounter,rules.number]"
                  v-model="sssss"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Max cards')"
                  type="number"
                  :rules="[rules.cardCounter,rules.number]"
                  v-model="max_cards"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Server ip')"
                  type="text"
                  :rules="[rules.ipAddress]"
                  v-model="server_ip"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Server port')"
                  type="number"
                  :rules="[rules.portCounter,rules.number]"
                  v-model="server_port"
              >
              </v-text-field>
            </v-flex>
            <template v-if="casType === 'dvcrypt'">
              <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Login')"
                  type="text"
                  v-model="login"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Password')"
                  type="text"
                  v-model="password"
              >
              </v-text-field>
            </v-flex>
            </template>
            <template v-else-if="casType === 'dexin'">
              <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Operator id')"
                  type="text"
                  v-model="operatorId"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm3 pr-3>
              <v-text-field
                  :label="t('Operator name')"
                  type="text"
                  v-model="operatorName"
              >
              </v-text-field>
            </v-flex>
            </template>
          </v-layout>
        </v-form>
        <v-divider>
        </v-divider>

        <v-subheader>{{ t('Upload DB') }}</v-subheader>
        <v-layout d-block pl-3>
          <v-text-field 
            :label="t('Select CSV')"
            readonly
            @click='pickFile'
            v-model="name_db"
            prepend-icon='attach_file'
            style="max-width: 200px"
          >
          </v-text-field>
          <input
              type="file"
              style="display: none"
              ref="csv"
              @change="onFilePicked"
          >
          <v-layout class="text-center">
            <v-btn :disabled="name_db === ''"
                   :loading="progress"
                   class="white--text"
                   color="purple darken-2"
                   v-on:click="submitDB"
            >
              {{ t("Submit DB") }}
            </v-btn>
            <v-btn 
              class="white--text" 
              color="purple darken-2" 
              @click.prevent="downloadDB()"
              v-if="!exportDB.exporting"
            >
              {{ t("Export DB") }}
            </v-btn>
            <v-btn 
              class="white--text" 
              color="red darken-4" 
              @click.prevent="cancelExport()"
              v-else
            >
              {{ t("Cancel  Export") }}
            </v-btn>
            <template v-if="exportDB.exporting">
              {{ exportDB.got }} / {{ exportDB.amount }}
            </template>
            <v-dialog
                v-model="progress"
                hide-overlay
                persistent
                width="300"
            >
              <v-card
                  color="primary"
                  dark
              >
                <v-card-text>
                  {{ t("Please, wait.DB is uploading") }}
                  <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-layout>
        </v-layout>
        <v-divider></v-divider>

        <v-subheader>{{ t('Last upload DB status') }}</v-subheader>
        <v-layout d-block pl-3 pb-3>
          <template v-if="uploadDBInfo">
            <v-layout class="text-center">
              <h3 class="subheading mr-1" d-inline>Finished</h3> 
              <check-or-close-icon :condition="uploadDBInfo.finished"/>
            </v-layout>
            <v-layout v-if="!uploadDBInfo.successful">
              <h3 class="subheading mr-1" d-inline>
                Errors in card IDs: 
                <div class="errors-in-cards">
                  {{ uploadDBInfo.errors.join(', ') }}
                </div>
              </h3>
            </v-layout>
          </template>
          <template v-else-if="uploadDBInfo === null">
            <h3 class="subheading"><i>No saved info</i></h3>
          </template>
          <template v-else>
            <h3 class="subheading"><i>Loading, please wait...</i></h3>
          </template>
        </v-layout>
        <v-divider></v-divider>

        <v-list three-line subheader>
          <v-subheader>{{ t('Synchronization of bouquets') }}</v-subheader>
          <v-list-tile avatar>
            <v-list-tile-content class="no-flex">
              <v-form ref="formBouquets">
                <v-text-field
                    :label="t('Bouquets')"
                    type="number"
                    :rules="[rules.bouquetsCounter]"
                    v-model="numOfBouquets"
                >
                </v-text-field>
              </v-form>
            </v-list-tile-content>
            <v-list-tile-avatar>
              <v-btn
                  color="primary"
                  dark
                  small
                  ripple
                  icon
                  class="mr-auto"
                  @click="updateBouquets"
              >
                <v-icon small>autorenew</v-icon>
              </v-btn>
            </v-list-tile-avatar>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>

        <v-list>
          <v-subheader>{{ t('Timezone') }}</v-subheader>
          <v-list-tile avatar>
            <v-list-tile-content class="no-flex">
              <search-select
                :items="timezoneChoices" 
                v-model="currentTimezone"
              >
              </search-select>
            </v-list-tile-content>
            <v-btn 
              color="primary"
              ripple
              @click="autoSelectTimezone"
            >
              {{ t("Auto-select") }}
            </v-btn>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
        
        <v-list>
          <v-subheader>{{ t('Clock') }}</v-subheader>
          <v-list-tile>
				    <v-flex sm3 pr-3>
					    <h3 class="subheading">{{ serverTime }}</h3>
				    </v-flex>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>

        <v-subheader>{{ t('Synchronization') }}</v-subheader>
        <v-list-tile avatar>
          <v-list-tile-content class="no-flex">
            <v-form ref="formBouquets">
              <v-text-field
                  :label="t('Periods quantity')"
                  type="number"
                  :rules="[rules.counterUpdatePeriods]"
                  v-model="counterUpdatePeriods"
              >
              </v-text-field>
            </v-form>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>

		    <v-subheader>{{ t('New synchronization periods:') }}</v-subheader>
        <v-form class="w-100 px-3" ref="formPeriods" @submit.prevent="synchronize()">
          <v-layout
              wrap
          >
            <v-flex sm3 pr-3 v-for="(period,key) in counterUpdatePeriodsArray" :key="key">
              <v-menu
                  :id="'menu'+key"
                  v-model="updatePeriodsArray[key].isOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="updatePeriodsArray[key].value"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="updatePeriodsArrayHack[key].value"
                      :label="t('Period') + ' ' + (key+1)"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-time-picker
                    format="24hr"
                    v-if="updatePeriodsArray[key].isOpen"
                    v-model="updatePeriodsArray[key].value"
                    full-width
                    @click:minute="updatePeriodsArrayHack[key].value = updatePeriodsArray[key].value"
                    :allowed-minutes="(m) => {return [0, 20, 40].includes(m);}"
                >
                </v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
        <v-divider></v-divider>

        <v-subheader>{{ t('Synchronization will start at:') }}</v-subheader>
        <v-layout wrap pl-3 pb-3 pt-0>
          <v-flex sm3 pr-3 v-for="(period,key) in serverPeriods" :key="key">
            <h3 class="subheading">{{ period.value }}</h3>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>

        <template v-if="!globalSynchronizationBeingExecuted">
		      <v-subheader v-if="lastSynchronize === null">{{ t('Loading last synchronization info...')}}</v-subheader>
          <template v-else-if="lastSynchronize !== null && lastSynchronize.hasOwnProperty('finished_at')">
            <v-subheader>{{ t('Last synchronization finished at:')}}</v-subheader>
			      <v-layout wrap pl-3 pb-3>
				      <v-flex sm3 pr-3>
					      <h3 class="subheading">{{ lastSynchronize.finished_at }}</h3>
				      </v-flex>
			      </v-layout>
            <v-divider> </v-divider>
		        <template v-if="synchronizeErrorsCount||CASConnectFailsCount">
			        <template v-if="synchronizeErrorsCount">
			          <v-subheader> {{ t('Synchronization error in cards:') }}</v-subheader>
			          <v-layout wrap pl-3 pb-3>
				          <v-flex 
				            sm3 
				            pr-3 
				          >
				            <h3
					          v-for="(error, key) in lastSynchronize.errors"
					          :key="key"
					          v-if="error.card_id !== null"
					          class="subheading"
				            >
					          №{{ error.card_id }}</h3>
				          </v-flex>
			          </v-layout>
                <v-divider> </v-divider>
			        </template>
			        <template v-if="CASConnectFailsCount">
			          <v-subheader> {{t('CAS connection fails:')}}</v-subheader>
			          <v-layout wrap pl-3 pb-3 pt-0>
				          <v-flex sm3 pr-3>
				            <h3 class="subheading">{{this.CASConnectFailsCount}}</h3>
				          </v-flex>
			          </v-layout>
                <v-divider> </v-divider>
			        </template>
			        <v-subheader>{{ t('Cards synchronization was not successful')}}</v-subheader>
		        </template>
		        <v-subheader v-else>{{ t('Cards synchronization was successful')}}</v-subheader>
		      </template>
		      <v-subheader v-else>{{ t('No synchronizations completed yet.')}}</v-subheader>
        </template>
        <template v-else>
          <v-subheader>
            {{ t('Synchronization started at') }}
          </v-subheader>
			    <v-layout wrap pl-3 pb-3>
				    <v-flex sm3 pr-3>
					    <h3 class="subheading"> {{ globalSynchronizationStartTime }}</h3>
				    </v-flex>
			    </v-layout>
          <v-divider> </v-divider>

          <v-subheader>
            {{ t('Synchronization progress') }}
          </v-subheader>
			    <v-layout wrap pl-3 pb-3>
				    <v-flex sm3 pr-3>
					    <h3 class="subheading"> 
					      {{ Math.floor(100 * globalSynchronization.current_progress / globalSynchronization.max_progress) }}%
					    </h3>
				    </v-flex>
			    </v-layout>
          <v-divider> </v-divider>

        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import CheckOrCloseIcon from '../Misc/CheckOrCloseIcon.vue';
import SearchSelect from '../Misc/SearchSelect.vue';

export default {
  components: {SearchSelect, CheckOrCloseIcon},
  data() {
    return {
      exportDB: {
        filename: '',
        got: -1,
        amount: 0,
        exporting: false
      },
      globalSynchronization: {
        current_progress: null,
        max_progress: null,
        progress_type: null,
        started_at: null,
      },
      uploadDBInfo: undefined,

      // here are stored frontend-choices (because they differ from 
      // choices, which are available from backend)
      timezoneChoices: [],
      currentTimezone: '',

      serverTime: '',

      progress: false,
      name_db: '',
      db: '',
      dialog: JSON.parse(localStorage.getItem('dialog')),
      snackbar: false,
      text: 'Oops... Something went wrong',
      timeout: 5000,
      loading: false,
      selector: false,
      kind: [
        {'text': 'Virtual balance', 'value': 'VIRTUAL'},
        {'text': 'Prepayment', 'value': 'PREPAYMENT'}
      ],
      currency: '',
      period: [
        {'text': 'Days', 'value': 'DAYS'},
        {'text': 'Weeks', 'value': 'WEEKS'},
        {'text': 'Months', 'value': 'MONTHS'}
      ],
      updatePeriodsArray: [{}, {}, {}, {}, {}, {}, {}, {}],
      updatePeriodsArrayHack: [{}, {}, {}, {}, {}, {}, {}, {}],
      counterUpdatePeriods: 1,
      serverPeriods: [],
      kind_payment: '',
      kind_period: '',
      numberOfPeriod: 1,
      max_cards: 0,
      server_ip: '',
      server_port: '',
      login: '',
      password: '',
      operatorId: 0,
      operatorName: '',
      casType: '',

      lastSynchronize: null,
      CASConnectFailsCount: 0,
      synchronizeErrorsCount: 0,
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        counter: value => value <= 100 || this.$translate.locale['Max 100'],
        currency: value => value.length <= 100 || this.$translate.locale["Max 100 characters"],
        counterUpdatePeriods: value => (value <= 8 && value >= 1) || this.$translate.locale['Min 1 Max 8'],
        bouquetsCounter: value => (value <= 128 && value >= 1) || this.$translate.locale['Min 1 Max 128'],
        number: value => {
          const pattern = /^\d+$/;
          if (value === '' || value === null) {
            return true
          }
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
        rrrCounter: value => (value <= 99999 && value >= 0 && value.length <= 5) || this.$translate.locale['Min 0 Max 99999'],
        cardCounter: value => (value <= 100000 && value >= 0) || this.$translate.locale['Min 0 Max 100000'],
        ipAddress: value => {
          const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          if (value === '' || value === null) {
            return true
          }
          return pattern.test(value) || this.$translate.locale['Invalid id address']
        },
        portCounter: value => (value <= 65535 && value >= 0) || this.$translate.locale['Min 0 Max 65535'],
      },
      sssss: '00000',
      numOfBouquets: 1,
    }
  },
  computed: {
    globalSynchronizationStartTime() {
      if (!this.currentTimezone) return '';
      
      const dateRaw = new Date(this.globalSynchronization.started_at * 1000);
      const date = this.convertTZ( dateRaw, this.currentTimezone, );

      let parts = [
          date.getHours(), date.getMinutes(), date.getDay(), date.getMonth(), date.getFullYear()
      ];
      let p = parts.map(p => ("0" + p).substr(-2));
      return `${p[0]}:${p[1]} ${p[2]}-${p[3]}-${parts[4]}`;
    },
    globalSynchronizationBeingExecuted() {
      return this.globalSynchronization.max_progress !== null;
    },
    counterUpdatePeriodsArray() {
      if (this.counterUpdatePeriods < 1) {
        return new Array(1)
      } else if (this.counterUpdatePeriods > 8) {
        return new Array(8)
      } else {
        return new Array(Number.parseInt(this.counterUpdatePeriods))
      }
    },
    ssssWithZeros() {
      if (this.sssss < 10 && this.sssss.length < 2) {
        return '0000' + this.sssss
      } else if (this.sssss < 100 && this.sssss.length < 3) {
        return '000' + this.sssss
      } else if (this.sssss < 1000 && this.sssss.length < 4) {
        return '00' + this.sssss
      } else if (this.sssss < 10000 && this.sssss.length < 5) {
        return '0' + this.sssss
      } else {
        return this.sssss.toString()
      }
    }
  },
  methods: {
    autoSelectTimezone() {
      let browserTimezone = this.toFrontendTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      
      if (!this.timezoneChoices.includes(browserTimezone)) {
        this.text = "Failed to auto-select timezone";
        this.snackbar = true;
        return;
      }

      this.currentTimezone = browserTimezone;
    },
    setupGlobalSynchronizationWebsocket() {
      const host = process.env.VUE_APP_BASE_URL || location.origin;

      let ws = new WebSocket(
        host.replace(/\/$/, '').replace(/^http/, "ws") + "/ws/global-sync"
      );
      ws.onmessage = (event) => {
        let data = JSON.parse(event.data).data;
        this.globalSynchronization = data;
      }
    },
    refreshTime() {
      if (!this.currentTimezone) return;

      let date = this.convertTZ(Date(), this.toBackendTimezone(this.currentTimezone));
      let parts = [date.getHours(), date.getMinutes(), date.getSeconds()];
      let p = parts.map(part => ('0' + part).substr(-2));
      this.serverTime = p.join(':');
    },
    convertTZ(date, tzString) {
      const newDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
      return newDate;
    },
    disableSite() {
      axios.get('/api/getFlag/')
          .then((response) => {
            localStorage.setItem('flag', response.data.flag);
            this.$store.commit('setFlag', response.data.flag);
          }).catch((error) => { });
    },
    downloadDB() {
      this.exportDB.got = -1;
      this.exportDB.amount = 0;

      let pointer = this;
      const host = process.env.VUE_APP_BASE_URL.replace(/\/$/, '');

      fetch(`${host}/api/export_db/`)
        .then(response => {
          this.exportDB.amount = Number(response.headers.get('cards-count'));
          this.exportDB.exporting = true;
          this.exportDB.filename = response.headers.get('custom-filename');

          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
                return pump();

                function pump() {
                  return reader.read().then(({done, value}) => {
                    if (pointer.exportDB.cancelToggle) {
                      pointer.exportDB.cancelToggle = false;
                      pointer.exportDB.exporting = false;
                      reader.cancel();
                      return
                    }

                    if (done) {
                      controller.close();
                      pointer.exportDB.exporting = false;
                      return
                    }

                    // counting \n in decoded, as it means that row of a card info 
                    // has ended
                    let decoded = new TextDecoder().decode(value);
                    pointer.exportDB.got += (decoded.match(/\n/g) || []).length;
                    controller.enqueue(value);
                    return pump();
                  })
                  .catch(e => {
                    pointer.text = e.respones.data.details;
                    pointer.snackbar = true;
                  });
                }
              }
          })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
			    let href = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = pointer.exportDB.filename;
          a.href = href;
          a.click();
			    URL.revokeObjectURL(href);
        })
        .catch(e => {
          pointer.text = e.respones.data.details;
          pointer.snackbar = true;
        });
    },
    cancelExport() {
      this.exportDB.cancelToggle = true;
    },
    submitDB() {
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };
      this.progress = true;
      let formData = new FormData();
      formData.append('file', this.db);
      axios.post('api/upload_db/', formData, config
      ).then((response) => {
        localStorage.setItem('task_id', response.data.task_id);
        this.text = this.$translate.locale['Successful'];
        this.snackbar = true;
        this.name_db = '';
        this.db = '';
        this.progress = false;
        this.disableSite();
      }).catch((error) => {
        this.text = error.response.data.details;
        this.snackbar = true;
        this.name_db = '';
        this.db = '';
        this.progress = false;
      });
    },
    pickFile() {
      this.$refs.csv.click()
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.name_db = files[0].name;
        this.db = files[0];
      } else {
        this.name_db = '';
        this.db = '';
      }
    },
    toBackendTimezone(frontendTimezone) {
      let timezone = String(frontendTimezone);
      
      if (timezone === "Europe/Kyiv")
        timezone = "Europe/Kiev";
      else if (/GMT\+/.test(timezone)) {
        timezone = timezone.replace('+', '-');
      } else if (/GMT\-/.test(timezone)) {
        timezone = timezone.replace('-', '+');
      }

      return timezone;
    },
    toFrontendTimezone(backendTimezone) {
      let timezone = String(backendTimezone);

      if (timezone === "Europe/Kiev")
        timezone = "Europe/Kyiv";
      else if (/GMT\-/.test(timezone)) {
        timezone = timezone.replace('-', '+');
      } else if (/GMT\+/.test(timezone)) {
        timezone = timezone.replace('+', '-');
      }

      return timezone;
    },
    getData() {
      this.loading = true;
      axios.get(`/api/settings/`)
        .then((response) => {
          if (response.status === 200) {
            this.currentTimezone = this.toFrontendTimezone(response.data.timezone);
            this.timezoneChoices = response.data.timezone_choices.map(
              this.toFrontendTimezone
            );
            this.kind_payment = response.data.kind_payment;
            this.currency = response.data.currency;
            this.kind_period = response.data.kind_period;
            this.numberOfPeriod = response.data.quantity;
            this.sssss = response.data.sssss;
            this.max_cards = response.data.max_cards;
            this.server_ip = response.data.server_ip;
            this.server_port = response.data.server_port;
            this.serverPeriods = response.data.periods;
            this.login = response.data.login;
            this.password = response.data.password;
            this.operatorId = response.data.operator_id;
            this.operatorName = response.data.operator_name;
            this.casType = response.data.cas_type;
            this.loading = false;
            this.$store.commit('set', {
              type: 'isPREPAYMENT',
              items: this.kind_payment === 'PREPAYMENT'
            });
            this.$store.commit('set', {
              type: 'currency',
              items: this.currency
            });
            setInterval(this.refreshTime, 1000);
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });

      this.getLastSynchronize()
    },
    saveSettings() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        this.loading = true;

        const timezone = this.toBackendTimezone(this.currentTimezone)

        axios.put(`/api/change-settings/`, {
          kind_payment: this.kind_payment,
          currency: this.currency,
          kind_period: this.kind_period,
          quantity: this.numberOfPeriod,
          sssss: this.ssssWithZeros,
          max_cards: this.max_cards,
          server_ip: this.server_ip,
          server_port: this.server_port,
          login: this.login,
          password: this.password,
          operator_id: this.operatorId,
          operator_name: this.operatorName,
          timezone,
        })
        .then((response) => {
          if (response.status === 200) {
            this.getData();
            this.text = "Settings changed!";
            this.snackbar = true;
            // this.dialog = false;
            this.loading = false;
            // this.$router.push('/dashboard');
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
          // this.dialog = false;
        });
        this.synchronize();
      }
    },
    updateBouquets() {
      if (!this.$refs.formBouquets.validate()) {
        this.text = "Fill the number of bouquets correctly";
        this.snackbar = true;
      } else {
        axios.get(`/api/download/${this.numOfBouquets}/`)
          .then((response) => {
            if (response.status === 200) {
              this.getData();
              this.text = "Bouquets updated!";
              this.snackbar = true;
              // this.dialog = false;
              // this.$router.push('/dashboard');
            }
          }).catch((error) => {
            this.text = error.response.data;
            this.snackbar = true;
          });
      }
    },
    synchronize() {
      if (!this.$refs.formPeriods.validate()) {
        this.text = "Fill the periods correctly";
        this.snackbar = true;
      } else {
        axios.post(`/api/synchronize/`, {periods: this.updatePeriodsArrayHack})
          .then((response) => {
            if (response.status === 200) {
              this.getData();
              this.updatePeriodsArray = [{}, {}, {}, {}, {}, {}, {}, {}]
              // this.text = "Periods updated!";
              // this.snackbar = true;
              // this.dialog = false;
              // this.$router.push('/dashboard');
            }
          }).catch((error) => {
            this.text = "Connection error";
            this.snackbar = true;
          })
      }
    },
    getLastSynchronize() {
      axios.get('/api/last-synchronize/').then((response) => {
        if (response.status === 200) {
          this.lastSynchronize = response.data;
		  this.lastSynchronize.errors = this.lastSynchronize.errors || [];	
	      this.CASConnectFailsCount = this.lastSynchronize.errors.reduce(
		    (a, b) => a + Boolean(b.cas_connect_failed), 0
		  );
		  this.synchronizeErrorsCount = this.lastSynchronize.errors.reduce(
		    (a, b) => a + Boolean(b.card_id), 0
		  );
        }
      }).catch(error => {
        this.text = "Error while getting last synchronization info";
        this.snackbar = true;
        console.error(error, error.stack);
      });
    },
    getUploadDBInfo() {
      axios.get('/api/upload_db/info')
        .then(response => {
          if (!response.statsus === 200) return;
          
          let info = response.data.results[0];

          if (info === undefined) 
            this.uploadDBInfo = null;
          else
            this.uploadDBInfo = info;
        })
        .catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
  },
  beforeMount() {
    this.getUploadDBInfo();
    this.getData();
    this.setupGlobalSynchronizationWebsocket();
  },
  watch: {
    'progress': {
      handler(val) {
        localStorage.setItem('progress', val);
      }
    },
    'dialog': {
      handler(val) {
        localStorage.setItem('dialog', val)
      }
    },
  },
}
</script>

<style scoped>
.no-flex {
  flex: initial;
}
.errors-in-cards {
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid purple;
  border-radius: 10px;
  padding: 5px;
}
</style>
