<template>
  <v-icon 
    color="green"
    v-if="condition"
  >
    check_bold
  </v-icon>
  <v-icon 
    color="red"
    v-else
  >
    close_thick
  </v-icon>
</template>
<script>
export default {
  props: ['condition'],
}
</script>
