<template>
  <v-navigation-drawer color="secondary"
                       app
                       fixed
                       clipped
                       :right="this.$vuetify.rtl"
                       v-model="menuVisible"
                       ref="menuBar">
    <v-divider/>
    <v-list 
      dense 
      :class="{
        'pt-3 mt-5': $vuetify.breakpoint.mdAndDown,
        'pt-0 mt-0': $vuetify.breakpoint.lgAndUp
      }"
      ref="buttonsList"
    >
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title 
            style="font-size: 15px"
          >
            <p class="text-xs-center">{{ t('Menu') }}</p>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile
        v-for="item in items"
        :key="item.title"
        :to="item.route"
      >
        <v-list-tile-action>
          <span class="bigger material-symbols-outlined">
            {{ item.icon }}
          </span>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title 
            style="font-size: 15px"
          >
            {{ t(item.title) }}
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>

    <!-- bottom part -->
    <v-list :style="bottomPartStyle" ref="actionList">
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title 
            sm3
            style="font-size: 15px"
          >
          {{ t('Page font size') }}
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action d-flex>
          <div>
            <span 
              v-ripple
              class="btn bigger material-symbols-outlined"
              @click="increaseFontScale"
            >
              zoom_in
            </span>
            <span 
              v-ripple
              class="btn bigger material-symbols-outlined"
              @click="decreaseFontScale"
            >
              zoom_out
            </span>
            <span
              v-ripple
              class="btn bigger material-symbols-outlined"
              @click="resetFontScale"
            >
              restart_alt
            </span>
          </div>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile xs8 sm8 style="margin-top: 20px">
        <v-list-tile-content>
          <v-select
              :items="languages"
              :label="t('Choose')"
              solo
              light
              v-model="language"
              @change="changeLanguage"
          >
          </v-select>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "topPanelMenu",
  props: {},
  data() {
    return {
      language: '',
      bottomPartStickToBottom: true,
    }
  },
  computed: {
    bottomPartStyle() {
      if (this.bottomPartStickToBottom) {
        return {position: 'absolute', bottom: 0};
      }

      return '';
    },
    menuVisible: {
      get() {
        return this.$store.getters.menuVisible;
      },
      set(val) {
        this.$store.commit('set', {type: 'MenuVisible', items: val});
      }
    },
    items() {
      let items = [
        {title: 'Dashboard', icon: 'bar_chart_4_bars', route: '/dashboard'},
        {title: 'Subscribers', icon: 'groups', route: '/subscribers'},
        {title: 'Cards', icon: 'style', route: '/cards'},
        {title: 'Package cost', icon: 'price_change', route: '/packages'},
      ];

      if (this.$store.getters.isAdmin || this.$store.getters.isOwner) {
        items = items.concat([
          {title: 'Resellers', icon: 'user_attributes', route: '/resellers'},
        ]);
      }
      if (this.$store.getters.isOwner) {
        items.splice(3, 0,
            {title: 'Payment periods', icon: 'calendar_clock', route: '/payment_periods'})
        items.splice(6, 0,
            {title: 'Reseller activation', icon: 'person_check', route: '/resellersActivation'})
      }

      if (this.$store.getters.isAdmin
	      || this.$store.getters.isReseller
		  || this.$store.getters.isManager) {
		items.push(
		  {title: 'Logs', icon: 'menu_book', route: '/logs'}
		)
	  }

	  items.push({title: 'Reports', icon: 'description', route: '/reports'},)
      return items;
    },
    languages() {
      // if (this.isMobile) {
      //   return [
      //     {'text': 'en', 'value': 'english'},
      //     {'text': 'ar', 'value': 'arabian'},
      //     {'text': 'ku', 'value': 'kurdish'}
      //   ]
      // }
      return [
        {'text': 'English', 'value': 'english'},
        {'text': 'Arabian', 'value': 'arabian'},
        {'text': 'Kurdish', 'value': 'kurdish'}
      ]
    }
  },
  methods: {
    increaseFontScale() {
      this.$store.dispatch('increaseFontScale');
    },
    decreaseFontScale() {
      this.$store.dispatch('decreaseFontScale');
    },
    resetFontScale() {
      this.$store.dispatch('resetFontScale');
    },
    changeLanguage() {
      localStorage.setItem('currentLanguage', this.language);
      this.$translate.setLang(this.language);
      this.$vuetify.rtl = window.$languages.locale[this.language].rtl;
      this.$router.go(this.$router.currentRoute)
    },
  },
  mounted() {
    new ResizeObserver(el => {
      const buttonsListHeight = this.$refs.buttonsList.$el.clientHeight;
      const actionListHeight = this.$refs.actionList.$el.clientHeight;
      const menuContainerHeight = this.$refs.menuBar.$el.clientHeight;
      
      this.bottomPartStickToBottom = buttonsListHeight + actionListHeight + 50 < menuContainerHeight
    }).observe(this.$refs.menuBar.$el)
  },
  beforeMount() {
    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'english');
    }
    this.language = localStorage.getItem('currentLanguage');
    this.$translate.setLang(this.language);
    this.$vuetify.rtl = window.$languages.locale[this.language].rtl;
  }
}
</script>

<style scoped>
.bigger {
  font-size: 35px !important;
}
span.btn {
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
