<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md>
	  <v-layout row wrap justify-center>
		<v-flex md10 class="d-flex" style="align-items: center">
			<v-card class="px-3 mb-3 pb-3">
				<v-layout class="d-flex">
					<v-flex cols="6" lg="6" class="mr-5">
						<v-menu
							ref="menu1"
							v-model="menu1"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="datelt"
									:label="t('from')"
									:hint="t('yyyy-mm-dd format')"
									persistent-hint
									prepend-icon="event"
									@blur="datelt = parsedate(datelt)"
									v-on="on"
									readonly
								></v-text-field>
							</template>
							<v-date-picker v-model="datelt" no-title :max="datert" @input="menu1 = false"></v-date-picker>
					</v-menu>
					</v-flex>
					<v-flex cols="6" lg="6">
						<v-menu
							v-model="menu2"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							full-width
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="datert"
									:label="t('to')"
									:hint="t('yyyy-mm-dd format')"
									persistent-hint
									prepend-icon="event"
									@blur="datert = parsedate(datert)"
									readonly
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker v-model="datert" no-title  :min="datelt" @input="menu2 = false"></v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex cols="1" lg="1">
						<!-- <v-btn dark flat @click="getdata">{{t('save')}}</v-btn> -->
						<v-btn @click="getData" color="success" :disabled="!datelt || !datert" dark style="margin-top: 20px;">{{t('apply')}}</v-btn>
					</v-flex>
				</v-layout>
			</v-card>
		</v-flex>
	  </v-layout>
      <v-layout row wrap>
	    <v-flex>
			<v-layout row wrap justify-center :class="{'mobile': mobile}">
				<v-flex md5 sm12 justify-center>
				  <v-card>
					<div id="cardChart" style="max-height: 400px"></div>
				  </v-card>
				  <v-card>
					<div id="cardPieChart" style="max-height: 400px"></div>
				  </v-card>
				</v-flex>
				<v-flex md5 sm12 justify-center>
				  <v-card>
					<div id="moneyChart" style="max-height: 400px"></div>
				  </v-card>
				  <v-card>
					<div id="moneyPieChart" style="max-height: 400px"></div>
				  </v-card>
				</v-flex>
			</v-layout>
		</v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';

import Highcharts from 'highcharts/highstock';

window.Highcharts = Highcharts;
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);

export default {
  name: "Home",
  data() {
    return {
	  menu1: false,
	  menu2: false,
	  datelt: '',
	  datert: '',
      selection: 'one_year',
      amount: 0,
      series: [],
      cardChart: {},
      cardPieChart: {},
      cardChartOptions: {
        title: {
          text: this.t('Cards')
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [{
                text: this.t('Export to PNG (small)'),
                onclick: function () {
                  this.exportChart({
                    width: 250
                  });
                }
              }, {
                text: this.t('Export to PNG (large)'),
                onclick: function () {
                  this.exportChart();
                },
                separator: false
              }]
            }
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        labels: {
          items: [{
            html: '',
            style: {
              left: '25px',
              top: '5px',
              fontSize: '15px',
              fontWeight: '700',
              maxWidth: '350px'
            }
          }
          ]
        },
        tooltip: {
          useHTML: true,
        },
        yAxis: {
          title: {
            text: this.t('Count')
          },
        },
        series: [],
      },
      cardPieChartOptions: {
        title: {
          text: ''
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [{
                text: this.t('Export to PNG (small)'),
                onclick: function () {
                  this.exportChart({
                    width: 250
                  });
                }
              }, {
                text: this.t('Export to PNG (large)'),
                onclick: function () {
                  this.exportChart();
                },
                separator: false
              }]
            }
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        labels: {
          items: [{
            html: '',
            style: {
              left: '25px',
              top: '5px',
              fontSize: '15px',
              fontWeight: '700',
              maxWidth: '350px'
            }
          }
          ]
        },
        tooltip: {
          useHTML: true,
        },
        yAxis: {
          title: {
            text: this.t('Count')
          },
        },
        series: [],
      },
      ////
      moneyChart: {},
      moneyPieChart: {},
      moneyChartOptions: {
        title: {
          text: this.t('Money')
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [{
                text: this.t('Export to PNG (small)'),
                onclick: function () {
                  this.exportChart({
                    width: 250
                  });
                }
              }, {
                text: this.t('Export to PNG (large)'),
                onclick: function () {
                  this.exportChart();
                },
                separator: false
              }]
            }
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        labels: {
          items: [{
            html: '',
            style: {
              left: '25px',
              top: '5px',
              fontSize: '15px',
              fontWeight: '700',
              maxWidth: '350px'
            }
          }
          ]
        },
        tooltip: {
          useHTML: true,
        },
        yAxis: {
          title: {
            text: this.t('Count')
          },
        },
        series: [],
      },
      moneyPieChartOptions: {
        title: {
          text: ''
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [{
                text: this.t('Export to PNG (small)'),
                onclick: function () {
                  this.exportChart({
                    width: 250
                  });
                }
              }, {
                text: this.t('Export to PNG (large)'),
                onclick: function () {
                  this.exportChart();
                },
                separator: false
              }]
            }
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        labels: {
          items: [{
            html: '',
            style: {
              left: '25px',
              top: '5px',
              fontSize: '15px',
              fontWeight: '700',
              maxWidth: '350px'
            }
          }
          ]
        },
        tooltip: {
          useHTML: true,
        },
        yAxis: {
          title: {
            text: 'Count'
          },
        },
        series: [],
      },
    }
  },
  computed: {
    balance() {
      return parseFloat(this.$store.getters.getBalance).toFixed(2) + this.$store.getters.currency
    },
    mobile() {
      return window.innerWidth < 500;
    }
  },
  methods: {
    clearPrevCharts() {
	  this.moneyChartOptions.series = [];
	  this.moneyPieChartOptions.series = [];
	  this.cardChartOptions.series = [];
	  this.cardPieChartOptions.series = [];
	},
	setDefaultDates() {
		this.datelt = this.$moment().startOf('month').format('YYYY-MM-DD');
		this.datert = this.$moment().endOf('month').format('YYYY-MM-DD');
	},
    getData() {
      axios.get('/api/dashboard/', {
		params: {date_gt: this.datelt, date_lt: this.datert}
	  }).then((response) => {
            this.prepareForDrawing(response.data);
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    prepareForDrawing(data) {
	  this.clearPrevCharts();
      this.drawCardGraphic(data);
      this.drawMoneyGraphic(data);
    },
    drawCardGraphic(responseData) {
      let cards = [];
      responseData.map(data => {
        cards[data.username] = [];
        data.cards.map(card => {
          let date = this.$moment(new Date(card.created_at)).format('MM-DD-YYYY');
          if (cards[data.username][date]) {
            cards[data.username][date].push(card.id);
          } else {
            cards[data.username][date] = [card.id];
          }
        });
      });
      let totalData = [];
      let totalAll = 0;
      for (const name in cards) {
        let data = [];
        let total = 0
        for (const date in cards[name]) {
          let d = new Date(date);
          const resDate = d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
          total += cards[name][date].length;
          data.push([resDate, cards[name][date].length]);
        }

        this.cardChartOptions.series.push({
          name: '<span>' + name + '</span>',
          data: data,
          type: 'line'
        })
        totalData.push({
          name: '<span>' + name + '</span>',
          y: total
        })
        totalAll += total;
      }
      this.cardPieChartOptions.series.push({
        type: 'pie',
        name: this.t('Total'),
        data: totalData,
        center: ['50%', '50%'],
        size: 200,
        showInLegend: true,
        dataLabels: {
          enabled: true
        }
      });
      this.cardPieChartOptions.labels.items[0].html = this.t('Total cards = ') + parseFloat(totalAll).toFixed();
      this.cardChart = new Highcharts.chart("cardChart", this.cardChartOptions);
      this.cardPieChart = new Highcharts.chart("cardPieChart", this.cardPieChartOptions);
    },
    drawMoneyGraphic(responseData) {
      let finances = [];
      responseData.map(data => {
        finances[data.username] = [];
        data.reports_finance.map(money => {
          let date = this.$moment(new Date(money.created_at)).format('MM-DD-YYYY');
          if (finances[data.username][date]) {
            finances[data.username][date] += money.spend_money;
            // finances[data.username][date].push(money.id);
          } else {
            finances[data.username][date] = money.spend_money;
            // finances[data.username][date] = [money.id];
          }
        });
      });
      let totalData = [];
      let totalAll = 0;
      for (const name in finances) {
        let data = [];
        let total = 0
        for (const date in finances[name]) {
          let d = new Date(date);
          const resDate = d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
          total += finances[name][date];
          data.push([resDate, finances[name][date]]);
        }

        this.moneyChartOptions.series.push({
          name: '<span>' + name + '</span>',
          data: data,
          type: 'line'
        })
        totalData.push({
          name: '<span>' + name + '</span>',
          y: total
        })
        totalAll += total;
      }
      this.moneyPieChartOptions.series.push({
        type: 'pie',
        name: this.t('Total'),
        data: totalData,
        center: ['50%', '50%'],
        size: 200,
        showInLegend: true,
        dataLabels: {
          enabled: true
        }
      });
      this.moneyPieChartOptions.labels.items[0].html = this.t('Total spend money = ') + parseFloat(totalAll).toFixed();
      this.moneyChart = new Highcharts.chart("moneyChart", this.moneyChartOptions);
      this.moneyPieChart = new Highcharts.chart("moneyPieChart", this.moneyPieChartOptions);
    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/login')
    }
    //  else if (!this.$store.getters.isAdmin) {
    // this.$router.push('/cards')
    // }
  },
  mounted() {
    this.setDefaultDates();
    this.getData();
  }
}

</script>

<style lang="scss">
.apexcharts-toolbar, .apexcharts-tooltip {
  z-index: 2 !important;
}

.wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  /*background-color: gold;*/
}

.data_block {
  width: 35%;
  height: 387px;
  /*background-color: #fff;*/
  margin-right: 20px;
  /*border-radius: 25px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
  /*-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
  /*box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/
}

.mobile {
  flex-direction: column !important;
}
</style>
