<template>
  <v-menu 
    v-model="menu"
    :close-on-content-click=false
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="inputVal"
        v-on="on"
      >
      </v-text-field>
    </template>

    <v-card>
      <v-layout mx-3 mb-0>
        <v-text-field
          placeholder="Search"
          v-model="search">
        </v-text-field>  
      </v-layout>

      <v-list 
        pt-0 
        style="max-height: 300px; overflow-y: auto;"
        ref="content"
      >
        <v-list-tile
          @click="menu = false"
          v-for="something in slicedItems"
          :key="something"
        >
          <v-list-tile-content 
            @click="inputVal = something"
          >
            {{ something }} 
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: [],
    value: '',
  },
  data() {
    return {
      menu: false,
      search: '',
      content: null,
      lastItem: 20,
    }
  },
  methods: {
   onScroll() {
    if (this.lastItem >= this.filteredItems.length) return

    const showMoreItems = (
      this.content.scrollHeight -
      (this.content.scrollTop +
      this.content.clientHeight)
    ) < 200


    if (showMoreItems) {
      this.lastItem += 20
    }
   }
  },
  computed: {
    slicedItems() {
      return this.filteredItems.slice(0, this.lastItem);
    },
    filteredItems() {
      return this.items
        .filter(i => i.toLowerCase().includes(this.search.toLowerCase()));
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted() {
    this.content = this.$refs.content.$el;
    this.content.addEventListener('scroll', this.onScroll, false);
  }
}
</script>
